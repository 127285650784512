const $ = require('jQuery');
const { $window } = require('../lib/selectors');

const MIN_HEIGHT = 480;

/**
 * Sets an element to take up the full height of the screen
 */
$.fn.fullScreen = function fullScreen() {
  return this.each((index, el) => {
    const $el = $(el);

    let windowHeight = $window.innerHeight();

    if (windowHeight < MIN_HEIGHT) {
      windowHeight = MIN_HEIGHT;
    }

    const fullscreenHeight = windowHeight - $el.offset().top;

    $el.css('height', `${fullscreenHeight}px`);
  });
};
