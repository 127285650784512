const $ = require('jQuery');

/**
 * Changes background images in response to the screen size
 */
$.fn.responsiveBackground = function responsiveBackground(opts = { shouldForceUpdate: false }) {
  let prevBreakpoint;

  const { shouldForceUpdate } = opts;

  const newBreakpoint = getCurrentBreakpoint();

  if (shouldForceUpdate || (newBreakpoint !== prevBreakpoint)) {
    changeBackgrounds(this, newBreakpoint);
    prevBreakpoint = newBreakpoint;
  }

  return this;
};


// ---------------------------------------------------------------------------


function getCurrentBreakpoint() {
  const windowWidth = window.innerWidth;
  let breakpoint;

  if (windowWidth >= 1200) {
    breakpoint = 'large';
  } else if (windowWidth >= 600 && windowWidth < 1200) {
    breakpoint = 'medium';
  } else {
    breakpoint = 'small';
  }

  return breakpoint;
}


// ---------------------------------------------------------------------------


function changeBackgrounds($containers, breakpoint) {
  $containers.each((index, el) => {
    const $container = $(el);
    const backgroundImage = $container.data(`bg-img-${breakpoint}`);

    if (backgroundImage) {
      $container.css('background-image', `url('${backgroundImage}')`);
    }
  });
}
