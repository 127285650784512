const $ = require('jQuery');
const debounce = require('./debounce');
const { $window } = require('./selectors');

/**
 * CSS Class names
 */
const classes = {
  scrolled: 'js-scrolled',
  scrolledUp: 'js-scrolled--up',
  scrolledDown: 'js-scrolled--down',
};


/**
 * Previous scroll position
 *
 * @type {number|null}
 */
let prevScrollTop = null;


// -----------------------------------------------------------------------------


$window.on('scroll', handleScroll);

$window.on('scroll-up', debounce(handleScrollUp));
$window.on('scroll-down', debounce(handleScrollDown));
$window.on('scroll-top', debounce(handleScrollTop));


// -----------------------------------------------------------------------------


function handleScroll() {
  const newScrollTop = $window.scrollTop();
  const scrollDirection = getScrollDirection(newScrollTop);

  // Trigger events indicating the scroll direction
  if (newScrollTop === 0) {
    $window.trigger('scroll-top', { newScrollTop });
  } else {
    $window.trigger(`scroll-${scrollDirection}`, { newScrollTop });
  }
}


// -----------------------------------------------------------------------------


function handleScrollTop() {
  const $body = $('body');

  const allClasses = [
    classes.scrolled,
    classes.scrolledUp,
    classes.scrolledDown,
  ].join(' ');

  $body.removeClass(allClasses);
}


// -----------------------------------------------------------------------------


function handleScrollUp() {
  const $body = $('body');

  const newClasses = [
    classes.scrolled,
    classes.scrolledUp,
  ].join(' ');

  $body.removeClass(classes.scrolledDown);
  $body.addClass(newClasses);
}


// -----------------------------------------------------------------------------


function handleScrollDown() {
  const $body = $('body');

  const newClasses = [
    classes.scrolled,
    classes.scrolledDown,
  ].join(' ');

  $body.removeClass(classes.scrolledUp);
  $body.addClass(newClasses);
}


// -----------------------------------------------------------------------------


function getScrollDirection(newScrollTop) {
  let direction = null;

  if (newScrollTop < prevScrollTop) {
    direction = 'up';
  } else if (newScrollTop > prevScrollTop) {
    direction = 'down';
  }

  prevScrollTop = newScrollTop;

  return direction;
}
