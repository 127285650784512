(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
'use strict';

var $ = (window.jQuery);

var debounce = require('../lib/debounce');

var _require = require('../lib/selectors');

var $window = _require.$window;
var $document = _require.$document;


module.exports = function backToTop() {
  var MIN_SCROLL_DISTANCE = 300;
  var SCROLL_DURATION = 750;

  var $button = $('.back-to-top');
  var $footer = $('.page-footer');

  $(window).on('resize scroll', debounce(handleUpdate));

  // Smooth scroll to top
  $button.on('click', function (evt) {
    evt.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, SCROLL_DURATION);
  });

  // ---------------------------------------------------------------------------


  /**
   * Determines whether to hide or show the 'Back to Top' button, and ensures
   * that it always stays above the footer.
   */
  function handleUpdate() {
    var newScrollTop = $window.scrollTop();

    if (newScrollTop < MIN_SCROLL_DISTANCE) {
      $button.removeClass('js-show');
      return;
    }

    var windowHeight = $window.height();
    var windowBottom = newScrollTop + windowHeight;

    var docHeight = $document.outerHeight(true);

    var footerHeight = $footer.outerHeight(true);

    var bottomOffset = docHeight - windowHeight - footerHeight;

    if (newScrollTop > bottomOffset) {
      // Ensure the button stays above the footer
      var footerOffset = $footer.offset().top;
      var buttonOffset = windowBottom - footerOffset;

      $button.css({ transform: 'translateY(-' + buttonOffset + 'px)' });
    } else {
      // Button is outside the footer. No need to translate it.
      $button.css({ transform: 'none' });
    }

    $button.addClass('js-show');
  }
};

},{"../lib/debounce":3,"../lib/selectors":5}],2:[function(require,module,exports){
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var $ = (window.jQuery);

var debounce = require('../lib/debounce');

var _require = require('../lib/selectors');

var $window = _require.$window;

/**
 * Utility function to wrap a classname in a css-style selector
 *
 * @param {string} className
 * @return {string}
 */

function selector(className) {
  return '.' + className;
}

var DEFAULT_CLASSES = {
  masthead: 'masthead',
  mastheadShow: 'js-maximized',
  mastheadHide: 'js-minimized',
  mastheadPinned: 'js-pinned',
  mastheadTransitioning: 'js-transitioning',

  menu: 'menu',
  menuOpen: 'js-show-menu',
  subMenuOpen: 'js-show-sub-menu',
  subMenuParent: 'menu__link--separator',

  hamburger: 'hamburger__button'
};

module.exports = function createMenuHandler() {
  var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

  var classes = _extends({}, DEFAULT_CLASSES, opts.classes);

  var $body = $('body');
  var $page = $('#page');
  var $masthead = $(selector(classes.masthead));
  var $menu = $(selector(classes.menu));
  var $hamburger = $(selector(classes.hamburger));

  // ===========================================================================
  // Handle toggling main menu
  // ---------------------------------------------------------------------------
  function handleToggleMainMenu(evt) {
    evt.preventDefault();

    $masthead.toggleClass(classes.menuOpen);
    $page.toggleClass(classes.menuOpen);
  }

  $hamburger.on('click', handleToggleMainMenu);

  // ===========================================================================
  // Handle toggling sub-menus open and closed
  // ---------------------------------------------------------------------------

  function handleToggleSubMenu(evt) {
    evt.preventDefault();

    var $menuItem = $(evt.currentTarget);

    $menuItem.parent().toggleClass(classes.subMenuOpen);
  }

  $menu.on('click', selector(classes.subMenuParent), handleToggleSubMenu);

  // ===========================================================================
  // Close open menus on resize
  // ---------------------------------------------------------------------------

  function closeMenus() {
    $masthead.removeClass(classes.menuOpen);
    $page.removeClass(classes.menuOpen);

    var $openMenus = $(selector(classes.subMenuParent));

    $openMenus.each(function (index, el) {
      var $parent = $(el).parent();
      $parent.removeClass(classes.subMenuOpen);
    });
  }

  function handleResize() {
    if ($window.outerWidth() > 1000) {
      closeMenus();
    }
  }

  $window.on('resize', debounce(handleResize));

  // ===========================================================================
  // Handle hiding / showing the menu on scroll
  // ---------------------------------------------------------------------------
  var MIN_SCROLL_DISTANCE = 200;
  var MASTHEAD_ANIMATION_DURATION = 200;

  function setBodyPaddingTop() {
    $body.css({ paddingTop: $masthead.innerHeight() });
  }

  $window.on('resize', debounce(setBodyPaddingTop));
  setBodyPaddingTop();

  function hideMasthead() {
    var shouldTransition = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

    $masthead.addClass(classes.mastheadPinned);

    if (shouldTransition) {
      $masthead.addClass(classes.mastheadTransitioning);
      $masthead.removeClass(classes.mastheadShow);

      window.setTimeout(function () {
        $masthead.removeClass(classes.mastheadTransitioning);
        $masthead.addClass(classes.mastheadHide);
      }, MASTHEAD_ANIMATION_DURATION);
    } else {
      $masthead.removeClass(classes.mastheadShow);
      $masthead.addClass(classes.mastheadHide);
    }
  }

  function showMasthead() {
    $masthead.removeClass(classes.mastheadHide);

    window.setTimeout(function () {
      $masthead.addClass([classes.mastheadTransitioning, classes.mastheadShow].join(' '));

      window.setTimeout(function () {
        $masthead.removeClass(classes.mastheadTransitioning);
      }, MASTHEAD_ANIMATION_DURATION);
    });
  }

  $window.on('scroll-down', debounce(function () {
    if ($masthead.hasClass(classes.menuOpen) || $masthead.hasClass(classes.mastheadHide) || $masthead.hasClass(classes.mastheadTransitioning)) {
      return;
    }

    if ($window.scrollTop() > MIN_SCROLL_DISTANCE) {
      var shouldTransition = $masthead.hasClass(classes.mastheadShow);

      hideMasthead(shouldTransition);
    }
  }));

  $window.on('scroll-up', debounce(function () {
    if (!$masthead.hasClass(classes.mastheadHide)) {
      return;
    }

    showMasthead();
  }));

  $window.on('scroll-top', function () {
    $masthead.removeClass([classes.mastheadShow, classes.mastheadPinned].join(' '));
  });
};

},{"../lib/debounce":3,"../lib/selectors":5}],3:[function(require,module,exports){
"use strict";

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @see http://davidwalsh.name/javascript-debounce-function
 */
module.exports = function debounce(func, wait, immediate) {
  var _this = this;

  var timeout = void 0;

  return function () {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var context = _this;
    var callNow = immediate && !timeout;

    var later = function later() {
      timeout = null;

      if (!immediate) {
        func.call.apply(func, [context].concat(args));
      }
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.call(context, args);
    }
  };
};

},{}],4:[function(require,module,exports){
'use strict';

var $ = (window.jQuery);
var debounce = require('./debounce');

var _require = require('./selectors');

var $window = _require.$window;

/**
 * CSS Class names
 */

var classes = {
  scrolled: 'js-scrolled',
  scrolledUp: 'js-scrolled--up',
  scrolledDown: 'js-scrolled--down'
};

/**
 * Previous scroll position
 *
 * @type {number|null}
 */
var prevScrollTop = null;

// -----------------------------------------------------------------------------


$window.on('scroll', handleScroll);

$window.on('scroll-up', debounce(handleScrollUp));
$window.on('scroll-down', debounce(handleScrollDown));
$window.on('scroll-top', debounce(handleScrollTop));

// -----------------------------------------------------------------------------


function handleScroll() {
  var newScrollTop = $window.scrollTop();
  var scrollDirection = getScrollDirection(newScrollTop);

  // Trigger events indicating the scroll direction
  if (newScrollTop === 0) {
    $window.trigger('scroll-top', { newScrollTop: newScrollTop });
  } else {
    $window.trigger('scroll-' + scrollDirection, { newScrollTop: newScrollTop });
  }
}

// -----------------------------------------------------------------------------


function handleScrollTop() {
  var $body = $('body');

  var allClasses = [classes.scrolled, classes.scrolledUp, classes.scrolledDown].join(' ');

  $body.removeClass(allClasses);
}

// -----------------------------------------------------------------------------


function handleScrollUp() {
  var $body = $('body');

  var newClasses = [classes.scrolled, classes.scrolledUp].join(' ');

  $body.removeClass(classes.scrolledDown);
  $body.addClass(newClasses);
}

// -----------------------------------------------------------------------------


function handleScrollDown() {
  var $body = $('body');

  var newClasses = [classes.scrolled, classes.scrolledDown].join(' ');

  $body.removeClass(classes.scrolledUp);
  $body.addClass(newClasses);
}

// -----------------------------------------------------------------------------


function getScrollDirection(newScrollTop) {
  var direction = null;

  if (newScrollTop < prevScrollTop) {
    direction = 'up';
  } else if (newScrollTop > prevScrollTop) {
    direction = 'down';
  }

  prevScrollTop = newScrollTop;

  return direction;
}

},{"./debounce":3,"./selectors":5}],5:[function(require,module,exports){
'use strict';

var $ = (window.jQuery);

module.exports = {
  $window: $(window),
  $document: $(document)
};

},{}],6:[function(require,module,exports){
'use strict';

var $ = (window.jQuery);

var _require = require('../lib/selectors');

var $window = _require.$window;


module.exports = function initializeFrontpage() {
  var $body = $('body');

  // Exit early if we're not on the front page
  if (!$body.hasClass('front')) {
    return;
  }

  var $block = $('.block--intro');

  var MIN_SCROLL_DISTANCE = 200;

  function handleScrollDown(e, _ref) {
    var newScrollTop = _ref.newScrollTop;

    if (newScrollTop < MIN_SCROLL_DISTANCE) {
      return;
    }

    $block.addClass('js-animated');
    $window.off('scroll-down', handleScrollDown);
  }

  $window.on('scroll-down', handleScrollDown);
};

},{"../lib/selectors":5}],7:[function(require,module,exports){
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var $ = (window.jQuery);

var DEFAULT_CONFIG = {
  hasStripes: false,
  shouldAutoClose: true,
  classes: {
    title: 'accordion__title',
    titleText: 'accordion__title-text',
    content: 'accordion__content',
    expanded: 'expanded',
    evenStripe: 'even',
    oddStripe: 'odd'
  }
};

$.fn.accordion = function accordion() {
  var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

  var config = _extends({}, DEFAULT_CONFIG, opts);

  this.each(function (index, accordionEl) {
    var $accordion = $(accordionEl);

    processTitles($accordion, index, config);

    $accordion.on('click tap', '.' + config.classes.title, handleAccordionToggle($accordion, config));
  });
};

// -----------------------------------------------------------------------------


function processTitles($accordion, accordionIndex, config) {
  // Target all the header tags as the title of each accordion section
  $accordion.children(':header').addClass(config.classes.titleText).wrap('<div class="' + config.classes.title + '">');

  var $titleWrappers = $('.' + config.classes.title, $accordion);

  // Process each title
  $titleWrappers.each(function (titleIndex, title) {
    // Use the text as id for the content, and the aria-controls attribute
    var $title = $(title);

    // If specified, add striping classnames
    if (config.hasStripes) {
      var stripeClass = titleIndex % 2 ? config.classes.oddStripe : config.classes.evenStripe;

      $title.addClass(stripeClass);
    }

    // Convert the title text to lowercase and filter out invalid chars
    var filteredTitleText = $title.text().toLowerCase().replace(/[^a-z^0-9."]/g, '-').replace('--', '-');

    // Add some accessability attributes to the titles
    $title.attr('aria-controls', filteredTitleText);

    // Add an id to the :header for analytics purposes
    $title.children(':header').attr('id', config.classes.title + '--' + accordionIndex + '-' + titleIndex);

    // wrap all the content until the next header tag
    $title.nextUntil('.' + config.classes.title).wrapAll('\n      <div\n        id="' + filteredTitleText + '"\n        class="' + config.classes.content + '"\n        aria-expanded="false"\n      >\n    ');

    $('#' + filteredTitleText).css({ display: 'none' });
  });
}

// -----------------------------------------------------------------------------


function handleAccordionToggle($accordion, config) {
  return function (evt) {
    evt.preventDefault();

    var $currTitle = $(evt.currentTarget);
    var $content = $currTitle.next('.' + config.classes.content);

    // Close other titles
    if (config.shouldAutoClose) {
      var $openSectionTitles = $('.' + config.classes.title + '.' + config.classes.expanded, $accordion).not($currTitle);

      $openSectionTitles.each(function (index, sectionTitle) {
        var $sectionTitle = $(sectionTitle);

        $sectionTitle.removeClass(config.classes.expanded);

        $sectionTitle.next('.' + config.classes.content).attr('aria-expanded', 'false').removeClass(config.classes.expanded).slideToggle(250);
      });
    }

    // toggle the class on the controlling element (mostly for styling)
    $currTitle.toggleClass(config.classes.expanded);

    $content.toggleClass(config.classes.expanded).slideToggle(250);

    var isExpanded = $content.hasClass(config.classes.expanded);

    $content.attr('aria-expanded', isExpanded ? 'true' : 'false');
  };
}

},{}],8:[function(require,module,exports){
'use strict';

var $ = (window.jQuery);
var debounce = require('../lib/debounce');

$.fn.autoExpand = function autoExpand() {
  return this.each(function (index, inputEl) {
    $(inputEl).on('input', debounce(handleInput));
  });
};

// -----------------------------------------------------------------------------

function handleInput(evt) {
  var $textarea = $(evt.target);
  var numLines = ($textarea.val().match(/\n/g) || []).length + 1;

  var baseHeight = 50;
  var lineHeight = $textarea.css('line-height').replace(/[^-\d\.]/g, '');
  var newHeight = baseHeight + lineHeight * (numLines - 1);

  $textarea.attr('rows', numLines).css('height', newHeight);
}

},{"../lib/debounce":3}],9:[function(require,module,exports){
'use strict';

var $ = (window.jQuery);
var debounce = require('../lib/debounce');

var _require = require('../lib/selectors');

var $window = _require.$window;

// ---------------------------------------------------------------------------


$.fn.backgroundFader = function backgroundFader() {
  var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

  var handleScroll = createScrollHandler(this, opts);
  $window.on('scroll', debounce(handleScroll));
};

// ---------------------------------------------------------------------------


function createScrollHandler($container, opts) {
  var contentSelector = opts.contentSelector || '> *';

  var $content = $container.find(contentSelector);

  var $images = $container.children();
  var numImages = $images.length;
  var lastImageIndex = numImages - 1;

  var changeBackground = createBackgroundChanger($images);

  changeBackground(0);

  var prevInterval = void 0;

  return function () {
    var contentHeight = $content.outerHeight(true);

    var intervalHeight = contentHeight / numImages;
    var amountScrolled = $window.scrollTop() + $window.height();
    var currInterval = Math.floor(amountScrolled / intervalHeight) - 1;

    if (currInterval !== prevInterval) {
      if (currInterval < 1) {
        changeBackground(0);
      } else if (currInterval >= lastImageIndex) {
        changeBackground(lastImageIndex);
      } else {
        changeBackground(currInterval);
      }

      prevInterval = currInterval;
    }
  };
}

// ---------------------------------------------------------------------------


function createBackgroundChanger($images) {
  return function (imageIndex) {
    var $current = $images.filter('.active');
    var $next = $images.eq(imageIndex);

    // These need to occur in this order or things get a bit funky when scrolling
    // @todo: figure out why?
    $current.removeClass('active');
    $next.addClass('active');
  };
}

},{"../lib/debounce":3,"../lib/selectors":5}],10:[function(require,module,exports){
'use strict';

var $ = (window.jQuery);

var _require = require('../lib/selectors');

var $window = _require.$window;


var MIN_HEIGHT = 480;

/**
 * Sets an element to take up the full height of the screen
 */
$.fn.fullScreen = function fullScreen() {
  return this.each(function (index, el) {
    var $el = $(el);

    var windowHeight = $window.innerHeight();

    if (windowHeight < MIN_HEIGHT) {
      windowHeight = MIN_HEIGHT;
    }

    var fullscreenHeight = windowHeight - $el.offset().top;

    $el.css('height', fullscreenHeight + 'px');
  });
};

},{"../lib/selectors":5}],11:[function(require,module,exports){
'use strict';

var $ = (window.jQuery);

/**
 * Changes background images in response to the screen size
 */
$.fn.responsiveBackground = function responsiveBackground() {
  var opts = arguments.length <= 0 || arguments[0] === undefined ? { shouldForceUpdate: false } : arguments[0];

  var prevBreakpoint = void 0;

  var shouldForceUpdate = opts.shouldForceUpdate;


  var newBreakpoint = getCurrentBreakpoint();

  if (shouldForceUpdate || newBreakpoint !== prevBreakpoint) {
    changeBackgrounds(this, newBreakpoint);
    prevBreakpoint = newBreakpoint;
  }

  return this;
};

// ---------------------------------------------------------------------------


function getCurrentBreakpoint() {
  var windowWidth = window.innerWidth;
  var breakpoint = void 0;

  if (windowWidth >= 1200) {
    breakpoint = 'large';
  } else if (windowWidth >= 600 && windowWidth < 1200) {
    breakpoint = 'medium';
  } else {
    breakpoint = 'small';
  }

  return breakpoint;
}

// ---------------------------------------------------------------------------


function changeBackgrounds($containers, breakpoint) {
  $containers.each(function (index, el) {
    var $container = $(el);
    var backgroundImage = $container.data('bg-img-' + breakpoint);

    if (backgroundImage) {
      $container.css('background-image', 'url(\'' + backgroundImage + '\')');
    }
  });
}

},{}],12:[function(require,module,exports){
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* eslint-disable no-param-reassign */
var $ = (window.jQuery);

var _require = require('../lib/selectors');

var $document = _require.$document;

/**
 * Default configuration
 *
 * @type {Object}
 */

var DEFAULT_CONFIG = {
  edge: 'top',
  duration: 500,
  easing: 'swing'
};

/**
 * Create a plugin that smoothly scrolls to the element it's been assigned to
 *
 * @param {Object} opts - Options
 * @return {void};
 *
 * ---------------------------------------------------------------------------
 *
 * @example
 * $('.some-element').smoothScrollTo();
 *
 * @example
 * $('.some-element').smoothScrollTo();
 */
$.fn.smoothScrollTo = function smoothScrollTo() {
  var opts = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];

  var config = _extends({}, DEFAULT_CONFIG, opts);

  console.log(this);

  var position = $(this).position().top;

  if (config.edge === 'bottom') {
    position += this.outerHeight();
  }

  $('html, body').animate({ scrollTop: position }, config.duration, config.easing);
};

/**
 * Attach the plugin to all page-internal links
 */
$document.on('ready', function () {
  $('body').on('click', 'a[href^="#"]:not(".no-scroll")', function (evt) {
    evt.preventDefault();

    var targetId = $(evt.currentTarget).attr('href');

    if (targetId === '#') {
      return;
    }

    var $target = $(targetId);
    $target.smoothScrollTo({ duration: 1000 });
  });
});

},{"../lib/selectors":5}],13:[function(require,module,exports){
'use strict';

var $ = (window.jQuery);

var _require = require('../lib/selectors');

var $window = _require.$window;

/**
 * Animates elements that have scrolled into view
 */

$.fn.triggerAnimations = function triggerAnimations() {
  var windowHeight = $window.height();
  var windowTop = $window.scrollTop();
  var windowBottom = windowTop + windowHeight;

  return this.filter(':not(".js-animated")').each(function (index, el) {
    var $el = $(el);
    var $elTop = $el.offset().top;

    var animationOffset = windowBottom - windowHeight / 4;

    if (animationOffset > $elTop) {
      $el.addClass('js-animated');
    }
  });
};

},{"../lib/selectors":5}],14:[function(require,module,exports){
'use strict';

var $ = (window.jQuery);

var debounce = require('./lib/debounce');

var _require = require('./lib/selectors');

var $window = _require.$window;
var $document = _require.$document;

// jQuery plugins

require('./plugins/accordion');
require('./plugins/trigger-animations');
require('./plugins/auto-expand-text-areas');
require('./plugins/background-fader');
require('./plugins/responsive-background');
require('./plugins/fullscreen');
require('./plugins/smooth-scroll-to');

// Utilities
require('./lib/scroll-events');

// Components
var initMenu = require('./components/menu');
var initBackToTop = require('./components/back-to-top');

// Pages
var maybeInitFrontPage = require('./pages/front-page');
// const maybeInitGalleryPage = require('./pages/gallery');


// Start it up
// =============================================================================
$document.on('ready', function () {
  // Initialize some components
  initMenu();
  initBackToTop();

  // This doesn't actually initialize *every* page. The indidivual functions
  // check whether they should perform the necessary actions.
  maybeInitFrontPage();
  // maybeInitGalleryPage();

  // Make things fullscreen
  $('.js-fullscreen').fullScreen();

  // Configure accordions
  $('.accordion').accordion();

  // ---------------------------------------------------------------------------


  // Attach resizing handlers
  var $responsiveBackgrounds = $('.js-responsive-background');

  function redraw() {
    var opts = arguments.length <= 0 || arguments[0] === undefined ? { shouldForceUpdate: false } : arguments[0];

    $responsiveBackgrounds.responsiveBackground(opts);
  }

  $window.on('resize', debounce(redraw));
  redraw({ shouldForceUpdate: true });

  // ---------------------------------------------------------------------------


  // Animations
  var $hidden = $('.into-view');

  $(window).on('resize scroll', function () {
    return $hidden.triggerAnimations();
  }).trigger('scroll');
});

},{"./components/back-to-top":1,"./components/menu":2,"./lib/debounce":3,"./lib/scroll-events":4,"./lib/selectors":5,"./pages/front-page":6,"./plugins/accordion":7,"./plugins/auto-expand-text-areas":8,"./plugins/background-fader":9,"./plugins/fullscreen":10,"./plugins/responsive-background":11,"./plugins/smooth-scroll-to":12,"./plugins/trigger-animations":13}]},{},[14])

