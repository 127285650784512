const $ = require('jQuery');

const debounce = require('../lib/debounce');
const { $window } = require('../lib/selectors');


/**
 * Utility function to wrap a classname in a css-style selector
 *
 * @param {string} className
 * @return {string}
 */
function selector(className) {
  return `.${className}`;
}

const DEFAULT_CLASSES = {
  masthead: 'masthead',
  mastheadShow: 'js-maximized',
  mastheadHide: 'js-minimized',
  mastheadPinned: 'js-pinned',
  mastheadTransitioning: 'js-transitioning',

  menu: 'menu',
  menuOpen: 'js-show-menu',
  subMenuOpen: 'js-show-sub-menu',
  subMenuParent: 'menu__link--separator',

  hamburger: 'hamburger__button',
};

module.exports = function createMenuHandler(opts = {}) {
  const classes = Object.assign({}, DEFAULT_CLASSES, opts.classes);

  const $body = $('body');
  const $page = $('#page');
  const $masthead = $(selector(classes.masthead));
  const $menu = $(selector(classes.menu));
  const $hamburger = $(selector(classes.hamburger));


  // ===========================================================================
  // Handle toggling main menu
  // ---------------------------------------------------------------------------
  function handleToggleMainMenu(evt) {
    evt.preventDefault();

    $masthead.toggleClass(classes.menuOpen);
    $page.toggleClass(classes.menuOpen);
  }

  $hamburger.on('click', handleToggleMainMenu);


  // ===========================================================================
  // Handle toggling sub-menus open and closed
  // ---------------------------------------------------------------------------

  function handleToggleSubMenu(evt) {
    evt.preventDefault();

    const $menuItem = $(evt.currentTarget);

    $menuItem.parent().toggleClass(classes.subMenuOpen);
  }

  $menu.on(
    'click',
    selector(classes.subMenuParent),
    handleToggleSubMenu
  );


  // ===========================================================================
  // Close open menus on resize
  // ---------------------------------------------------------------------------

  function closeMenus() {
    $masthead.removeClass(classes.menuOpen);
    $page.removeClass(classes.menuOpen);

    const $openMenus = $(selector(classes.subMenuParent));

    $openMenus.each((index, el) => {
      const $parent = $(el).parent();
      $parent.removeClass(classes.subMenuOpen);
    });
  }

  function handleResize() {
    if ($window.outerWidth() > 1000) {
      closeMenus();
    }
  }

  $window.on('resize', debounce(handleResize));


  // ===========================================================================
  // Handle hiding / showing the menu on scroll
  // ---------------------------------------------------------------------------
  const MIN_SCROLL_DISTANCE = 200;
  const MASTHEAD_ANIMATION_DURATION = 200;

  function setBodyPaddingTop() {
    $body.css({ paddingTop: $masthead.innerHeight() });
  }

  $window.on('resize', debounce(setBodyPaddingTop));
  setBodyPaddingTop();

  function hideMasthead(shouldTransition = false) {
    $masthead.addClass(classes.mastheadPinned);

    if (shouldTransition) {
      $masthead.addClass(classes.mastheadTransitioning);
      $masthead.removeClass(classes.mastheadShow);

      window.setTimeout(() => {
        $masthead.removeClass(classes.mastheadTransitioning);
        $masthead.addClass(classes.mastheadHide);
      }, MASTHEAD_ANIMATION_DURATION);
    } else {
      $masthead.removeClass(classes.mastheadShow);
      $masthead.addClass(classes.mastheadHide);
    }
  }

  function showMasthead() {
    $masthead.removeClass(classes.mastheadHide);

    window.setTimeout(() => {
      $masthead.addClass([
        classes.mastheadTransitioning,
        classes.mastheadShow,
      ].join(' '));

      window.setTimeout(() => {
        $masthead.removeClass(classes.mastheadTransitioning);
      }, MASTHEAD_ANIMATION_DURATION);
    });
  }

  $window.on('scroll-down', debounce(() => {
    if (
      $masthead.hasClass(classes.menuOpen)
      || $masthead.hasClass(classes.mastheadHide)
      || $masthead.hasClass(classes.mastheadTransitioning)
    ) {
      return;
    }

    if ($window.scrollTop() > MIN_SCROLL_DISTANCE) {
      const shouldTransition = $masthead.hasClass(classes.mastheadShow);

      hideMasthead(shouldTransition);
    }
  }));

  $window.on('scroll-up', debounce(() => {
    if (!$masthead.hasClass(classes.mastheadHide)) {
      return;
    }

    showMasthead();
  }));

  $window.on('scroll-top', () => {
    $masthead.removeClass([
      classes.mastheadShow,
      classes.mastheadPinned,
    ].join(' '));
  });
};

