const $ = require('jQuery');

const debounce = require('../lib/debounce');
const { $window, $document } = require('../lib/selectors');

module.exports = function backToTop() {
  const MIN_SCROLL_DISTANCE = 300;
  const SCROLL_DURATION = 750;

  const $button = $('.back-to-top');
  const $footer = $('.page-footer');

  $(window).on('resize scroll', debounce(handleUpdate));

  // Smooth scroll to top
  $button.on('click', (evt) => {
    evt.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, SCROLL_DURATION);
  });


  // ---------------------------------------------------------------------------


  /**
   * Determines whether to hide or show the 'Back to Top' button, and ensures
   * that it always stays above the footer.
   */
  function handleUpdate() {
    const newScrollTop = $window.scrollTop();

    if (newScrollTop < MIN_SCROLL_DISTANCE) {
      $button.removeClass('js-show');
      return;
    }

    const windowHeight = $window.height();
    const windowBottom = newScrollTop + windowHeight;

    const docHeight = $document.outerHeight(true);

    const footerHeight = $footer.outerHeight(true);

    const bottomOffset = docHeight - windowHeight - footerHeight;

    if (newScrollTop > bottomOffset) {
      // Ensure the button stays above the footer
      const footerOffset = $footer.offset().top;
      const buttonOffset = windowBottom - footerOffset;

      $button.css({ transform: `translateY(-${buttonOffset}px)` });
    } else {
      // Button is outside the footer. No need to translate it.
      $button.css({ transform: 'none' });
    }

    $button.addClass('js-show');
  }
};

