const $ = require('jQuery');

const DEFAULT_CONFIG = {
  hasStripes: false,
  shouldAutoClose: true,
  classes: {
    title: 'accordion__title',
    titleText: 'accordion__title-text',
    content: 'accordion__content',
    expanded: 'expanded',
    evenStripe: 'even',
    oddStripe: 'odd',
  },
};

$.fn.accordion = function accordion(opts = {}) {
  const config = Object.assign({}, DEFAULT_CONFIG, opts);

  this.each((index, accordionEl) => {
    const $accordion = $(accordionEl);

    processTitles($accordion, index, config);

    $accordion.on(
      'click tap',
      `.${config.classes.title}`,
      handleAccordionToggle($accordion, config)
    );
  });
};


// -----------------------------------------------------------------------------


function processTitles($accordion, accordionIndex, config) {
  // Target all the header tags as the title of each accordion section
  $accordion
    .children(':header')
    .addClass(config.classes.titleText)
    .wrap(`<div class="${config.classes.title}">`);

  const $titleWrappers = $(`.${config.classes.title}`, $accordion);

  // Process each title
  $titleWrappers.each((titleIndex, title) => {
    // Use the text as id for the content, and the aria-controls attribute
    const $title = $(title);

    // If specified, add striping classnames
    if (config.hasStripes) {
      const stripeClass = (titleIndex % 2)
        ? config.classes.oddStripe
        : config.classes.evenStripe;

      $title.addClass(stripeClass);
    }

    // Convert the title text to lowercase and filter out invalid chars
    const filteredTitleText = $title.text()
      .toLowerCase()
      .replace(/[^a-z^0-9."]/g, '-')
      .replace('--', '-');

    // Add some accessability attributes to the titles
    $title.attr('aria-controls', filteredTitleText);

    // Add an id to the :header for analytics purposes
    $title.children(':header').attr(
      'id',
      `${config.classes.title}--${accordionIndex}-${titleIndex}`
    );

    // wrap all the content until the next header tag
    $title.nextUntil(`.${config.classes.title}`).wrapAll(`
      <div
        id="${filteredTitleText}"
        class="${config.classes.content}"
        aria-expanded="false"
      >
    `);

    $(`#${filteredTitleText}`).css({ display: 'none' });
  });
}


// -----------------------------------------------------------------------------


function handleAccordionToggle($accordion, config) {
  return (evt) => {
    evt.preventDefault();

    const $currTitle = $(evt.currentTarget);
    const $content = $currTitle.next(`.${config.classes.content}`);

    // Close other titles
    if (config.shouldAutoClose) {
      const $openSectionTitles = $(
        `.${config.classes.title}.${config.classes.expanded}`,
        $accordion
      ).not($currTitle);

      $openSectionTitles.each((index, sectionTitle) => {
        const $sectionTitle = $(sectionTitle);

        $sectionTitle
          .removeClass(config.classes.expanded);

        $sectionTitle
          .next(`.${config.classes.content}`)
          .attr('aria-expanded', 'false')
          .removeClass(config.classes.expanded)
          .slideToggle(250);
      });
    }

    // toggle the class on the controlling element (mostly for styling)
    $currTitle.toggleClass(config.classes.expanded);

    $content
      .toggleClass(config.classes.expanded)
      .slideToggle(250);

    const isExpanded = $content.hasClass(config.classes.expanded);

    $content.attr('aria-expanded', isExpanded
      ? 'true'
      : 'false'
    );
  };
}
