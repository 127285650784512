const $ = require('jQuery');

const debounce = require('./lib/debounce');
const { $window, $document } = require('./lib/selectors');

// jQuery plugins
require('./plugins/accordion');
require('./plugins/trigger-animations');
require('./plugins/auto-expand-text-areas');
require('./plugins/background-fader');
require('./plugins/responsive-background');
require('./plugins/fullscreen');
require('./plugins/smooth-scroll-to');

// Utilities
require('./lib/scroll-events');

// Components
const initMenu = require('./components/menu');
const initBackToTop = require('./components/back-to-top');

// Pages
const maybeInitFrontPage = require('./pages/front-page');
// const maybeInitGalleryPage = require('./pages/gallery');


// Start it up
// =============================================================================
$document.on('ready', () => {
  // Initialize some components
  initMenu();
  initBackToTop();

  // This doesn't actually initialize *every* page. The indidivual functions
  // check whether they should perform the necessary actions.
  maybeInitFrontPage();
  // maybeInitGalleryPage();

  // Make things fullscreen
  $('.js-fullscreen').fullScreen();

  // Configure accordions
  $('.accordion').accordion();


  // ---------------------------------------------------------------------------


  // Attach resizing handlers
  const $responsiveBackgrounds = $('.js-responsive-background');

  function redraw(opts = { shouldForceUpdate: false }) {
    $responsiveBackgrounds.responsiveBackground(opts);
  }

  $window.on('resize', debounce(redraw));
  redraw({ shouldForceUpdate: true });


  // ---------------------------------------------------------------------------


  // Animations
  const $hidden = $('.into-view');

  $(window)
    .on('resize scroll', () => $hidden.triggerAnimations())
    .trigger('scroll');
});
