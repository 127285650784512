const $ = require('jQuery');
const { $window } = require('../lib/selectors');

/**
 * Animates elements that have scrolled into view
 */
$.fn.triggerAnimations = function triggerAnimations() {
  const windowHeight = $window.height();
  const windowTop = $window.scrollTop();
  const windowBottom = windowTop + windowHeight;

  return this
    .filter(':not(".js-animated")')
    .each((index, el) => {
      const $el = $(el);
      const $elTop = $el.offset().top;

      const animationOffset = windowBottom - (windowHeight / 4);

      if (animationOffset > $elTop) {
        $el.addClass('js-animated');
      }
    });
};
