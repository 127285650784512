const $ = require('jQuery');
const { $window } = require('../lib/selectors');

module.exports = function initializeFrontpage() {
  const $body = $('body');

  // Exit early if we're not on the front page
  if (!$body.hasClass('front')) {
    return;
  }

  const $block = $('.block--intro');

  const MIN_SCROLL_DISTANCE = 200;

  function handleScrollDown(e, { newScrollTop }) {
    if (newScrollTop < MIN_SCROLL_DISTANCE) {
      return;
    }

    $block.addClass('js-animated');
    $window.off('scroll-down', handleScrollDown);
  }

  $window.on('scroll-down', handleScrollDown);
};
