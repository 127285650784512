const $ = require('jQuery');
const debounce = require('../lib/debounce');

$.fn.autoExpand = function autoExpand() {
  return this.each((index, inputEl) => {
    $(inputEl).on('input', debounce(handleInput));
  });
};

// -----------------------------------------------------------------------------

function handleInput(evt) {
  const $textarea = $(evt.target);
  const numLines = ($textarea.val().match(/\n/g) || []).length + 1;

  const baseHeight = 50;
  const lineHeight = $textarea.css('line-height').replace(/[^-\d\.]/g, '');
  const newHeight = baseHeight + (lineHeight * (numLines - 1));

  $textarea
    .attr('rows', numLines)
    .css('height', newHeight);
}
