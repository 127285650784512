/* eslint-disable no-param-reassign */
const $ = require('jQuery');
const { $document } = require('../lib/selectors');

/**
 * Default configuration
 *
 * @type {Object}
 */
const DEFAULT_CONFIG = {
  edge: 'top',
  duration: 500,
  easing: 'swing',
};

/**
 * Create a plugin that smoothly scrolls to the element it's been assigned to
 *
 * @param {Object} opts - Options
 * @return {void};
 *
 * ---------------------------------------------------------------------------
 *
 * @example
 * $('.some-element').smoothScrollTo();
 *
 * @example
 * $('.some-element').smoothScrollTo();
 */
$.fn.smoothScrollTo = function smoothScrollTo(opts = {}) {
  const config = Object.assign({}, DEFAULT_CONFIG, opts);

  console.log(this);

  let position = $(this).position().top;

  if (config.edge === 'bottom') {
    position += this.outerHeight();
  }

  $('html, body').animate(
    { scrollTop: position },
    config.duration,
    config.easing
  );
};


/**
 * Attach the plugin to all page-internal links
 */
$document.on('ready', () => {
  $('body').on('click', 'a[href^="#"]:not(".no-scroll")', (evt) => {
    evt.preventDefault();

    const targetId = $(evt.currentTarget).attr('href');

    if (targetId === '#') {
      return;
    }

    const $target = $(targetId);
    $target.smoothScrollTo({ duration: 1000 });
  });
});
