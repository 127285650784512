/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @see http://davidwalsh.name/javascript-debounce-function
 */
module.exports = function debounce(func, wait, immediate) {
  let timeout;

  return (...args) => {
    const context = this;
    const callNow = immediate && !timeout;

    const later = () => {
      timeout = null;

      if (!immediate) {
        func.call(context, ...args);
      }
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.call(context, args);
    }
  };
};
