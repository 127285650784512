const $ = require('jQuery');
const debounce = require('../lib/debounce');
const { $window } = require('../lib/selectors');


// ---------------------------------------------------------------------------


$.fn.backgroundFader = function backgroundFader(opts = {}) {
  const handleScroll = createScrollHandler(this, opts);
  $window.on('scroll', debounce(handleScroll));
};


// ---------------------------------------------------------------------------


function createScrollHandler($container, opts) {
  const contentSelector = opts.contentSelector || '> *';

  const $content = $container.find(contentSelector);

  const $images = $container.children();
  const numImages = $images.length;
  const lastImageIndex = numImages - 1;

  const changeBackground = createBackgroundChanger($images);

  changeBackground(0);

  let prevInterval;

  return () => {
    const contentHeight = $content.outerHeight(true);

    const intervalHeight = contentHeight / numImages;
    const amountScrolled = $window.scrollTop() + $window.height();
    const currInterval = Math.floor(amountScrolled / intervalHeight) - 1;

    if (currInterval !== prevInterval) {
      if (currInterval < 1) {
        changeBackground(0);
      } else if (currInterval >= lastImageIndex) {
        changeBackground(lastImageIndex);
      } else {
        changeBackground(currInterval);
      }

      prevInterval = currInterval;
    }
  };
}


// ---------------------------------------------------------------------------


function createBackgroundChanger($images) {
  return (imageIndex) => {
    const $current = $images.filter('.active');
    const $next = $images.eq(imageIndex);

    // These need to occur in this order or things get a bit funky when scrolling
    // @todo: figure out why?
    $current.removeClass('active');
    $next.addClass('active');
  };
}
